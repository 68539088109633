var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kibanaErrorsList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.errorsList),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.errorsList),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"dateCell",fn:function(ref){
      var props = ref.props;
return [_c('td',[_vm._v(_vm._s(_vm.dateTime_dateTimeSeconds(_vm.kgm_getNestedValue(props.field, props.dataItem))))])]}},{key:"messageCell",fn:function(ref){
      var props = ref.props;
return [_c('td',{staticStyle:{"overflow":"hidden","white-space":"nowrap"},attrs:{"id":"message"}},[_vm._v(" "+_vm._s(props.dataItem.message)+" ")])]}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.copyToClipboard(props.dataItem.message)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"copy"}}),_c('span',[_vm._v("Copy Message")])],1)])])]}},{key:"optionsTemplateSm",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.copyToClipboard(props.dataItem.message)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"copy"}})],1)])])]}},{key:"levelFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"levelFilterValue",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a level"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('levelFilterValue', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"dateFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"dateFilterValue",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a date"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('dateFilterValue', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"messageFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"messageFilterValue",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a message"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('messageFilterValue', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }