<template>
  <div class="kibanaErrorsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(errorsList)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(errorsList)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateCell"
          slot-scope="{props}"
        >
          <td>{{ dateTime_dateTimeSeconds(kgm_getNestedValue(props.field, props.dataItem)) }}</td>
        </template>
        <template
          slot="messageCell"
          slot-scope="{props}"
        >
          <td
            id="message"
            style="overflow: hidden;white-space: nowrap;"
          >
            {{ props.dataItem.message }}
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="copyToClipboard(props.dataItem.message)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="copy"
                />
                <span>Copy Message</span>
              </button>
            </div>
          </td>
        </template>
        <template
          slot="optionsTemplateSm"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="copyToClipboard(props.dataItem.message)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="copy"
                />
              </button>
            </div>
          </td>
        </template>
        <div
          slot="levelFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="levelFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a level"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('levelFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="dateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="dateFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('dateFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="messageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="messageFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a message"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('messageFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";

export default {
  name: "KibanaErrorsList",
  mixins: [kendoGridMixin, dateTimeMixin],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      errorsList: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "key",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          field: "key",
          dir: "asc"
        }
      ],
      kgm_columns: [
        {
          field: "level",
          filterable: true,
          filter: "text",
          title: "Level",
          filterCell: "levelFilter"
        },
        {
          field: "date",
          filterable: true,
          filter: "text",
          title: this.$t("date"),
          filterCell: "dateFilter",
          cell: "dateCell"
        },
        {
          field: "message",
          filterable: true,
          filter: "text",
          title: this.$t("message"),
          filterCell: "messageFilter",
          cell: "messageCell"
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplate",
          width: "150px",
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplateSm",
          hideOn: ['smUp'],
          width: "55px",
        }
      ]
    };
  },
  created () {
    this.getKibanaErrors();
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      this.$snotify.success("The data has been copied to your clipboard.");
    },
    stopLoading () {
      this.$emit("stopLoading");
    },
    //api calls
    getKibanaErrors () {
      this.loading = true;
      this.axios
        .get(`/Kibana/GetErrors?resortId=${this.resortId}`)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.errorsList = [];
          var hits = response.data.hits.hits;
          if (hits) {
            hits.forEach(e => {
              var source = e["_source"];
              var error = {
                date: source["@timestamp"],
                level: source.level,
                message: source.message
              };
              this.errorsList.push(error);
            });
          }
        })
        .finally(() => {
          this.loading = false;
          this.stopLoading();
        });
    }
  }
};
</script>
<style>
.kibanaErrorsList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>